import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header'; // Importar Header
import Footer from '../components/Footer'; // Importar Footer
import TripCard from '../components/TripCard'; // Assumindo que você já tem o componente TripCard
import '../pages/TripsList.css';

const TripsList = () => {
  const location = useLocation();
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const tripsPerPage = 9; // Definindo 2 viagens por página (pode ser ajustado)

  // Dados fictícios para uma visualização inicial
  const dummyTrips = [
    {
      id: 1,
      title: "Bonito - MS",
      dates: "12 mar - 16 mar",
      description: "Ecoturismo",
      price: "R$ 1.200,00",
      agencyLogo: "https://picsum.photos/30/30?random=1",
      agencyName: "Natureza Turismo",
      badge: "Popular",
      imageUrl: "https://picsum.photos/300/150?random=1"
    },
    {
      id: 2,
      title: "Chapada Diamantina - BA",
      dates: "20 abr - 25 abr",
      description: "Aventura",
      price: "R$ 1.500,00",
      agencyLogo: "https://picsum.photos/30/30?random=2",
      agencyName: "Aventura Brasil",
      badge: "Recomendado",
      imageUrl: "https://picsum.photos/300/150?random=2"
    },
    {
      id: 3,
      title: "Foz do Iguaçu - PR",
      dates: "05 mai - 09 mai",
      description: "Cachoeiras",
      price: "R$ 1.300,00",
      agencyLogo: "https://picsum.photos/30/30?random=3",
      agencyName: "Iguatour",
      badge: "Novidade",
      imageUrl: "https://picsum.photos/300/150?random=3"
    },
    {
      id: 4,
      title: "Rio de Janeiro - RJ",
      dates: "10 jun - 15 jun",
      description: "Fim de Semana",
      price: "R$ 1.800,00",
      agencyLogo: "https://picsum.photos/30/30?random=4",
      agencyName: "Carioca Tours",
      badge: "Top Seller",
      imageUrl: "https://picsum.photos/300/150?random=4"
    },
    {
      id: 5,
      title: "Gramado - RS",
      dates: "15 jul - 20 jul",
      description: "Luxo",
      price: "R$ 2.000,00",
      agencyLogo: "https://picsum.photos/30/30?random=5",
      agencyName: "Sul Turismo",
      badge: "Luxo",
      imageUrl: "https://picsum.photos/300/150?random=5"
    },
    {
      id: 6,
      title: "Jericoacoara - CE",
      dates: "01 set - 05 set",
      description: "Praia",
      price: "R$ 1.400,00",
      agencyLogo: "https://picsum.photos/30/30?random=6",
      agencyName: "Praia Brasil",
      badge: "Favorito",
      imageUrl: "https://picsum.photos/300/150?random=6"
    },
    {
      id: 7,
      title: "São Paulo - SP",
      dates: "22 ago - 26 ago",
      description: "Cultura",
      price: "R$ 1.100,00",
      agencyLogo: "https://picsum.photos/30/30?random=7",
      agencyName: "Paulistour",
      badge: "Recomendado",
      imageUrl: "https://picsum.photos/300/150?random=7"
    },
    {
      id: 8,
      title: "Manaus - AM",
      dates: "12 out - 17 out",
      description: "Aventura",
      price: "R$ 1.700,00",
      agencyLogo: "https://picsum.photos/30/30?random=8",
      agencyName: "Amazontour",
      badge: "Exploração",
      imageUrl: "https://picsum.photos/300/150?random=8"
    },
    {
      id: 9,
      title: "Salvador - BA",
      dates: "05 nov - 10 nov",
      description: "Cultura",
      price: "R$ 1.600,00",
      agencyLogo: "https://picsum.photos/30/30?random=9",
      agencyName: "Bahia Tours",
      badge: "Cultural",
      imageUrl: "https://picsum.photos/300/150?random=9"
    },
    {
      id: 10,
      title: "Pantanal - MS",
      dates: "22 dez - 27 dez",
      description: "Ecoturismo",
      price: "R$ 2.200,00",
      agencyLogo: "https://picsum.photos/30/30?random=10",
      agencyName: "Pantanal Safári",
      badge: "Aventura",
      imageUrl: "https://picsum.photos/300/150?random=10"
    },
    {
      id: 11,
      title: "Porto Seguro - BA",
      dates: "02 jan - 07 jan",
      description: "Praia",
      price: "R$ 1.250,00",
      agencyLogo: "https://picsum.photos/30/30?random=11",
      agencyName: "Verão Turismo",
      badge: "Verão",
      imageUrl: "https://picsum.photos/300/150?random=11"
    },
    {
      id: 12,
      title: "Caldas Novas - GO",
      dates: "15 fev - 20 fev",
      description: "Relaxamento",
      price: "R$ 900,00",
      agencyLogo: "https://picsum.photos/30/30?random=12",
      agencyName: "Água Quente",
      badge: "Relaxamento",
      imageUrl: "https://picsum.photos/300/150?random=12"
    },
    {
      id: 13,
      title: "Lençóis Maranhenses",
      dates: "05 mar - 10 mar",
      description: "Aventura",
      price: "R$ 1.850,00",
      agencyLogo: "https://picsum.photos/30/30?random=13",
      agencyName: "Nordeste Destinos",
      badge: "Aventura",
      imageUrl: "https://picsum.photos/300/150?random=13"
    }
  ];
  

  useEffect(() => {
    setLoading(true);
    // Simula uma busca de viagens - aqui você pode integrar com sua API real
    setTimeout(() => {
      setTrips(dummyTrips);
      setLoading(false);
    }, 1000); // Simula uma espera de 1 segundo
  }, []);

  // Função para lidar com a mudança de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Cálculo de paginação
  const indexOfLastTrip = currentPage * tripsPerPage;
  const indexOfFirstTrip = indexOfLastTrip - tripsPerPage;
  const currentTrips = trips.slice(indexOfFirstTrip, indexOfLastTrip);

  // Gerar números de página
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(trips.length / tripsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <Header /> {/* Adiciona o Header */}
      <div className="trips-list-page container mt-4">
        <h2>Resultados da Busca</h2>

        <div className="trips-container">
          {loading ? (
            <p>Carregando viagens...</p>
          ) : currentTrips.length > 0 ? (
            currentTrips.map(trip => (
              <TripCard
                key={trip.id}
                imageUrl={trip.imageUrl}
                title={trip.title}
                dates={trip.dates}
                description={trip.description}
                price={trip.price}
                agencyLogo={trip.agencyLogo}
                agencyName={trip.agencyName}
                badge={trip.badge}
                tripId={trip.id}
              />
            ))
          ) : (
            <p>Nenhuma viagem encontrada.</p>
          )}
        </div>

        {/* Paginação com estilo UX/UI */}
        <nav aria-label="Page navigation example" className="mt-4">
          <ul className="pagination justify-content-center">
            {pageNumbers.map((number) => (
              <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                <button className="page-link" onClick={() => handlePageChange(number)}>
                  {number}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <Footer /> {/* Adiciona o Footer */}
    </>
  );
};

export default TripsList;
