// src/components/TripDetailsInfo.js
import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const TripDetailsInfo = ({ inclusions, exclusions, itemsToBring }) => {
  return (
    <div className="trip-details-info mt-4">
      <h4>Incluso</h4>
      <ul>
        {inclusions.map((item, index) => (
          <li key={index}>
            <FaCheckCircle className="text-success me-2" />
            {item}
          </li>
        ))}
      </ul>

      <h4>Não Incluso</h4>
      <ul>
        {exclusions.map((item, index) => (
          <li key={index}>
            <FaTimesCircle className="text-danger me-2" />
            {item}
          </li>
        ))}
      </ul>

      <h4>O que levar</h4>
      <ul>
        {itemsToBring.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

export default TripDetailsInfo;
