// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import TripDetails from './pages/TripDetails';
import TripsList from './pages/TripsList'; 

function App() {
  return (
    <Router>
      <Routes>
        {/* Rota para a página inicial */}
        <Route path="/" element={<Home />} />

        {/* Rota para a página de detalhes de viagem */}
        <Route path="/detalhes-viagem/:id" element={<TripDetails />} />

        {/* Rota para a página de listagem de viagens */}
        <Route path="/viagens" element={<TripsList />} />

      </Routes>
    </Router>
  );
}

export default App;
