import React from 'react';
import { Helmet } from 'react-helmet'; // Importação para SEO
import Header from '../components/Header';
import Footer from '../components/Footer';
import TripGallery from '../components/TripGallery';
import TripDetailsInfo from '../components/TripDetailsInfo';
import { FaCcVisa, FaCcMastercard, FaPaypal } from 'react-icons/fa'; // Ícones de pagamento
import './TripDetails.css';
import TripDepartureCard from '../components/TripDepartureCard'; 
import AgencyCard from '../components/AgencyCard';

const TripDetails = () => {
    const trip = {
        title: "Arraial do Cabo",
        startDate: "24/07/2024",
        endDate: "27/08/2024",
        price: "R$ 1.500,00",
        description: "Divirta-se em Arraial do Cabo durante 3 dias intensos, conhecendo 3 praias de cinema e uma noite maravilhosa!",
        inclusions: ["Pousada", "Passeio de barco"],
        exclusions: ["Almoço/Janta"],
        itemsToBring: ["Protetor solar", "Óculos de sol", "Cobertor"],
        schedule: [
            { day: "Dia 1", activity: "Passeio de barco nas ilhas" },
            { day: "Dia 2", activity: "Dia livre para passeios" },
            { day: "Dia 3", activity: "Praia pela manhã, volta para casa às 18h" },
        ],
        departures: [
            { 
                location: "Rodoviária de Piracicaba", 
                address: "Rua dos Exemplos, 213 - Centro", // Novo campo de endereço
                date: "24/07/2024", 
                time: "19:00" 
            },
            { 
                location: "Shopping de Campinas", 
                address: "Avenida das Flores, 101 - Bairro", // Novo campo de endereço
                date: "24/07/2024", 
                time: "20:30" 
            },
        ],
    };
    
    return (
        <>
            <Helmet>
                <title>{trip.title} | Detalhes da Viagem</title>
                <meta name="description" content={trip.description} />
                <meta property="og:image" content="https://picsum.photos/1920/1080?random=15" />
            </Helmet>

            <Header />
            <main className="trip-details-page">
                <TripGallery />

                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-12">
                            {/* Título da Viagem */}
                            <div className="trip-header d-flex justify-content-between align-items-center">
                                <h1 className="trip-title">{trip.title}</h1>
                            </div>
                            <p className="trip-dates">Ida: {trip.startDate} | Volta: {trip.endDate}</p>

                            {/* Descrição */}
                            <div className="trip-description section-container">
                                <h4>Descrição</h4>
                                <p>{trip.description}</p>
                            </div>

                            {/* Programação */}
                            <div className="trip-schedule mt-3">
                                <h4>Programação</h4>
                                {trip.schedule.map((item, index) => (
                                    <div key={index} className="day-program">
                                        <h6><strong>{item.day}</strong></h6>
                                        <p>{item.activity}</p>
                                    </div>
                                ))}
                            </div>

                            {/* Saídas */}
                            <div className="trip-departures mt-3">
                                <h4>Saídas</h4>
                                {trip.departures.map((departure, index) => (
                                    <TripDepartureCard
                                        key={index}
                                        location={departure.location}
                                        address={departure.address}
                                        date={departure.date}
                                        time={departure.time}
                                    />
                                ))}
                            </div>

                            {/* Inclusos/Não Inclusos */}
                            <TripDetailsInfo 
                                inclusions={trip.inclusions} 
                                exclusions={trip.exclusions}
                                itemsToBring={trip.itemsToBring} 
                            />

                            {/* Agência Responsável */}
                            <div className="agency-contact mt-5">
                                <AgencyCard 
                                    name="Agência X"
                                    email="contato@agenciax.com.br"
                                    whatsapp="5511999999999"
                                    cadastur="123456789"
                                />
                            </div>

                            {/* FAQ */}
                            <div className="faq-section mt-5">
                                <h4>Perguntas Frequentes</h4>
                                <div className="faq-item">
                                    <strong>Como posso cancelar a reserva?</strong>
                                    <p>Você pode cancelar até 7 dias antes da viagem, sem custos adicionais.</p>
                                </div>
                                <div className="faq-item">
                                    <strong>Posso levar crianças?</strong>
                                    <p>Sim, crianças de todas as idades são bem-vindas.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
            <Footer />

            <div className="floating-card">
                <p className="price-label">A partir de:</p>
                <p className="price-value">{trip.price}</p>
                <p className="urgency-text">Restam poucas vagas!</p>
                <p className="installments">Em até 10x sem juros</p>
                <div className="payment-methods">
                    <FaCcVisa size={30} />
                    <FaCcMastercard size={30} />
                    <FaPaypal size={30} />
                </div>
                <button className="btn btn-primary">Comprar Viagem</button>
            </div>
        </>
    );
};

export default TripDetails;
