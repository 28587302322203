import React from 'react';
import { FaRegComments } from 'react-icons/fa'; // Ícone de chat
import './AgencyCard.css'; // Importa o CSS do card da agência

const AgencyCard = ({ name, email, whatsapp, cadastur }) => {
    return (
        <div className="agency-card">
            {/* Foto da Agência */}
            <div className="agency-photo">
                <img src="https://picsum.photos/200/200?random=1" alt={`${name} Logo`} />
            </div>

            {/* Nome e Informações da Agência */}
            <div className="agency-info">
                <h4>{name}</h4>
                <p>Email: <a href={`mailto:${email}`}>{email}</a></p>
                <p>Cadastur: {cadastur}</p>
            </div>

            {/* Botão de WhatsApp com Ícone de Chat */}
            <a 
                href={`https://wa.me/${whatsapp}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="btn-whatsapp"
            >
                <FaRegComments size={24} />
            </a>
        </div>
    );
};

export default AgencyCard;
