// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SearchBar from '../components/SearchBar';
import TripCard from '../components/TripCard';
import StatisticsSection from '../components/StatisticsSection';
import { useNavigate } from 'react-router-dom';

const Home = () => {

  const handleSort = (event) => {
    const sortBy = event.target.value;
    alert(`Ordenando por: ${sortBy}`);
  };

  const navigate = useNavigate();

  const handleSearch = (filters) => {
    // Redireciona para a página de listagem de viagens com filtros (por enquanto fictício)
    navigate(`/viagens?origem=${filters.origem}&destino=${filters.destino}`);
  };

  return (
    <>
      <Header />
      <main>
        <section className="hero-section">
          <div className="background-image"></div>
          <div className="hero-text">
            <h1>Explore o mundo. Viva a experiência</h1>
          </div>
          {/* Barra de Busca */}
          <SearchBar onSearch={handleSearch} />
        </section>

        {/* Seção de Categorias */}
        <section className="categories-section">
          <h2 className="section-title">Categorias</h2>
          <div className="categories-container">
            <div className="category-card" style={{ backgroundImage: "url('https://picsum.photos/300/250?random=1')" }}>
              <span><i className="fa fa-hiking"></i> Aventura</span>
            </div>
            <div className="category-card" style={{ backgroundImage: "url('https://picsum.photos/300/250?random=2')" }}>
              <span><i className="fa fa-landmark"></i> Cultural</span>
            </div>
            <div className="category-card" style={{ backgroundImage: "url('https://picsum.photos/300/250?random=3')" }}>
              <span><i className="fa fa-umbrella-beach"></i> Praia</span>
            </div>
            <div className="category-card" style={{ backgroundImage: "url('https://picsum.photos/300/250?random=4')" }}>
              <span><i className="fa fa-music"></i> Show</span>
            </div>
            <div className="category-card" style={{ backgroundImage: "url('https://picsum.photos/300/250?random=5')" }}>
              <span><i className="fa fa-tree"></i> Natureza</span>
            </div>
            <div className="category-card" style={{ backgroundImage: "url('https://picsum.photos/300/250?random=6')" }}>
              <span><i className="fa fa-users"></i> Família</span>
            </div>
          </div>
        </section>

        {/* Seção de Destinos Populares */}
        <section className="popular-destinations-section">
          <h2 className="section-title">Destinos Populares</h2>
          <div className="destinations-container">
            <div className="destination-card">
              <img src="https://picsum.photos/540/350?random=4" alt="Arraial do Cabo" />
              <span className="destination-name">Arraial do Cabo</span>
            </div>
            <div className="destination-card">
              <img src="https://picsum.photos/300/150?random=5" alt="Fernando de Noronha" />
              <span className="destination-name">Fernando de Noronha</span>
            </div>
            <div className="destination-card">
              <img src="https://picsum.photos/300/150?random=6" alt="Foz do Iguaçu" />
              <span className="destination-name">Foz do Iguaçu</span>
            </div>
            <div className="destination-card">
              <img src="https://picsum.photos/550/150?random=7" alt="Chapada Diamantina" />
              <span className="destination-name">Chapada Diamantina</span>
            </div>
          </div>
        </section>

        {/* Seção de Próximas Saídas */}
        <section className="upcoming-trips-section">
          <div className="section-header">
            <h2>Próximas Saídas</h2>
            <div className="sort-options">
              <label htmlFor="sort-by" className="sort-label">Ordenar por:</label>
              <select id="sort-by" className="form-select form-select-sm" onChange={handleSort}>
                <option value="relevancia">Relevância</option>
                <option value="menor-preco">Menor Preço</option>
                <option value="maior-preco">Maior Preço</option>
              </select>
            </div>
          </div>
          <div className="trips-container">
            {/* Passando tripId em cada card de viagem */}
            <TripCard 
              imageUrl="https://picsum.photos/350/150?random=8" 
              title="Bonito" 
              dates="05 mar - 09 mar" 
              description="Ecoturismo" 
              price="R$ 1.200,00" 
              agencyLogo="https://picsum.photos/30/30?random=1" 
              agencyName="Bruturismo" 
              badge="Popular" 
              tripId="1" 
            />
            <TripCard 
              imageUrl="https://picsum.photos/350/150?random=9" 
              title="Jalapão" 
              dates="12 abr - 16 abr" 
              description="Aventura" 
              price="R$ 1.800,00" 
              agencyLogo="https://picsum.photos/30/30?random=2" 
              agencyName="Karina Turismo" 
              badge="Novidade" 
              tripId="2" 
            />
            <TripCard 
              imageUrl="https://picsum.photos/350/150?random=10" 
              title="Lençóis Maranhenses" 
              dates="20 mai - 25 mai" 
              description="Paisagens" 
              price="R$ 1.500,00" 
              agencyLogo="https://picsum.photos/30/30?random=3" 
              agencyName="Agência X" 
              badge="Recomendado" 
              tripId="3" 
            />
            {/* Outros Cards de Viagem */}
          </div>
        </section>

        {/* Seção de Estatísticas */}
        <StatisticsSection />
      </main>
      <Footer />
    </>
  );
};

export default Home;
