// src/components/TripDepartureCard.js
import React from 'react';
import { FaBusAlt } from 'react-icons/fa'; // Ícone de ônibus
import './TripDepartureCard.css'; // Importa o CSS específico do card de saídas

const TripDepartureCard = ({ location, address,date, time }) => {
    return (
        <div className="departure-card">
            <FaBusAlt className="bus-icon" />
            <div className="departure-info">
                <p className="departure-location">{location}</p>
                <p className="departure-address">{address}</p>
                <p className="departure-details">Data: {date} | Hora: {time}</p>
            </div>
        </div>
    );
};

export default TripDepartureCard;
