// src/components/TripGallery.js
import React from 'react';
import { Carousel } from 'react-bootstrap'; // Importando o Carousel do Bootstrap

const TripGallery = ({ tripId }) => {
  // Simulando as imagens para o carrossel
  const images = [
    'https://picsum.photos/1920/600?random=1',
    'https://picsum.photos/1920/600?random=2',
    'https://picsum.photos/1920/600?random=3'
  ];

  return (
    <section className="trip-gallery">
      <Carousel>
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={image}
              alt={`Slide ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
};

export default TripGallery;
