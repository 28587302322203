// src/components/SearchBar.js
import React from 'react';

const SearchBar = ({ onSearch }) => (
  <div className="search-bar">
    <form className="row g-2 justify-content-center align-items-center">
      <div className="col-md-2 form-floating position-relative">
        <input type="text" className="form-control" id="input-origem" placeholder="Origem" />
        <label htmlFor="input-origem"><i className="fa fa-map-marker-alt me-2"></i>Origem</label>
      </div>
      <div className="col-md-2 form-floating position-relative">
        <input type="text" className="form-control" id="input-destino" placeholder="Destino" />
        <label htmlFor="input-destino"><i className="fa fa-map-marker-alt me-2"></i>Destino</label>
      </div>
      <div className="col-md-2 form-floating position-relative">
        <input type="date" className="form-control" id="data-ida" />
        <label htmlFor="data-ida"><i className="fa fa-calendar-alt me-2"></i>Data de Ida</label>
      </div>
      <div className="col-md-2 form-floating position-relative">
        <input type="date" className="form-control" id="data-volta" />
        <label htmlFor="data-volta"><i className="fa fa-calendar-alt me-2"></i>Data de Volta</label>
      </div>
      <div className="col-md-2 d-flex align-items-center">
      <button 
  className="btn btn-primary w-100" 
  type="button" 
  onClick={onSearch}
  aria-label="Buscar Viagem"
>
  <i className="fa fa-search"></i> Buscar
</button>
      </div>
    </form>
  </div>
);

export default SearchBar;
