// src/components/StatisticsSection.js
import React, { useEffect } from 'react';

const StatisticsSection = () => {
  useEffect(() => {
    const animateCounter = () => {
      const counters = document.querySelectorAll('.counter');
      counters.forEach((counter) => {
        const target = +counter.getAttribute('data-target');
        const updateCount = () => {
          const current = +counter.innerText;
          const increment = target / 200;

          if (current < target) {
            counter.innerText = Math.ceil(current + increment);
            setTimeout(updateCount, 10);
          } else {
            counter.innerText = target;
          }
        };
        updateCount();
      });
    };

    const onScrollAnimateCounters = () => {
      const statsSection = document.querySelector('.statistics-section');
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animateCounter();
            observer.disconnect(); // Stop after first trigger
          }
        });
      });

      observer.observe(statsSection);
    };

    onScrollAnimateCounters();
  }, []);

  return (
    <section className="statistics-section py-5 bg-light">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-4">
            <i className="fa fa-building fa-3x mb-2" style={{ color: "var(--primary-color)" }}></i>
            <h3 id="agencias" className="counter" data-target="120">0</h3>
            <p>Agências Cadastradas</p>
          </div>
          <div className="col-md-4">
            <i className="fa fa-route fa-3x mb-2" style={{ color: "var(--primary-color)" }}></i>
            <h3 id="excursoes" className="counter" data-target="250">0</h3>
            <p>Excursões Cadastradas</p>
          </div>
          <div className="col-md-4">
            <i className="fa fa-plane fa-3x mb-2" style={{ color: "var(--primary-color)" }}></i>
            <h3 id="viagens" className="counter" data-target="580">0</h3>
            <p>Viagens Vendidas</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StatisticsSection;
